import { Ziggy } from '@/ziggy';
import { AxiosRequestConfig } from 'axios';
import { toRaw } from 'vue';
import AxiosClient from './AxiosClient';

type KeysOfType<T, U> = T extends object ? {
    [K in keyof T]-?: T[K] extends any[] ? never :
      T[K] extends U ? K :
      T[K] extends object ? `${K & string}.${KeysOfType<T[K], U> & string}` :
      never;
  }[keyof T] :
  '';

type GetNestedType<T, K extends string> = K extends `${infer Key}.${infer Rest}` ?
  Key extends keyof T ? GetNestedType<T[Key], Rest> :
  never :
  K extends keyof T ? T[K] :
  never;

type ResolveCastableTypeToTransformer<T extends keyof _ApiRequests, K> = K extends
  KeysOfType<_ApiRequests[T], Castable> ? (
    value: GetNestedType<_ApiRequests[T], K>,
    clone: _ApiRequests[T],
    key: string,
  ) => GetNestedType<ApiRequests[T], K> | null :
  (value: string | undefined) => string | undefined;

type RequestCasts<T extends keyof _ApiRequests> = {
  [K in KeysOfType<_ApiRequests[T], Castable>]: ResolveCastableTypeToTransformer<T, K>;
};

type ReplaceType<Type, FromType, ToType> = Type extends FromType // FromType?
 ? ToType // Yes, replace it
   :
  Type extends object // Recurse?
   ? ReplaceTypes<Type, FromType, ToType> // Yes
     :
  Type; // No, leave it alone

type ReplaceTypes<ObjType extends object, FromType, ToType> = {
  [KeyType in keyof ObjType]: ReplaceType<ObjType[KeyType], FromType, ToType>;
};

type ReplaceCastable<Type> = Type extends Castable ? Type['type'] : Type extends object ? ReplaceCastables<Type> : Type;

type ReplaceCastables<ObjType extends object> = {
  [KeyType in keyof ObjType]: ReplaceCastable<ObjType[KeyType]>;
};

type RequestType<T extends string> = T extends `${infer Prefix}Request` ? Prefix : never;

const hasOwnDotProperty = (obj: object, dotKey?: string) => {
  const splitKey = dotKey.split(/\.(.*)/s);
  const key = splitKey[0];

  if(splitKey.length > 1) {
    return hasOwnDotProperty(obj[key], splitKey[1]);
  }

  return Object.hasOwn(obj, key);
};

const getDot = (obj: object, dotKey?: string) => {
  const splitKey = dotKey.split(/\.(.*)/s);
  const key = splitKey[0];

  if(splitKey.length > 1) {
    return getDot(obj[key], splitKey[1]);
  }

  return obj[key];
};

const setDot = (obj: object, value: unknown, dotKey?: string) => {
  const splitKey = dotKey.split(/\.(.*)/s);
  const key = splitKey[0];

  if(splitKey.length > 1) {
    return setDot(obj[key], value, splitKey[1]);
  }

  obj[key] = value;

  return obj[key];
};

export async function api<T extends keyof typeof Ziggy['routes']>(
  routeUrl: T,
  type: RequestType<keyof typeof AxiosClient> & Lowercase<typeof Ziggy['routes'][T]['methods'][number]>,
  ...params: T extends keyof _ApiRequests ? [
      keyof RequestCasts<T> extends never ? { data: _ApiRequests[T] } :
        { data: ReplaceCastables<_ApiRequests[T]>; casts: RequestCasts<T> },
    ] :
    []
) {
  if(params.length > 0) {
    const clonedData = structuredClone(toRaw(params[0].data));

    if('casts' in params[0]) {
      for(const [key, transformer] of Object.entries(params[0].casts)) {
        setDot(clonedData, transformer(getDot(clonedData, key), clonedData, key), key);
      }
    }

    return await AxiosClient[`${type}Request`](routeUrl, clonedData);
  }

  return await AxiosClient[`${type}Request`](routeUrl);
}

export async function apiConfig<T extends keyof typeof Ziggy['routes']>(
  routeUrl: T,
  type: RequestType<keyof typeof AxiosClient> & Lowercase<typeof Ziggy['routes'][T]['methods'][number]>,
  config: AxiosRequestConfig = {},
  ...params: T extends keyof _ApiRequests ? [
      keyof RequestCasts<T> extends never ? { data: _ApiRequests[T] } :
        { data: ReplaceCastables<_ApiRequests[T]>; casts: RequestCasts<T> },
    ] :
    []
) {
  if(params.length > 0) {
    const clonedData = structuredClone(toRaw(params[0].data));

    if('casts' in params[0]) {
      for(const [key, transformer] of Object.entries(params[0].casts)) {
        setDot(clonedData, transformer(getDot(clonedData, key), clonedData, key), key);
      }
    }

    return await AxiosClient[`${type}Request`](routeUrl, clonedData, config);
  }

  return await AxiosClient[`${type}Request`](routeUrl, undefined, config);
}

export function castDatetimeOrTime(value: string, date: string) {
  const regex = /\d\d\d\d-\d\d-\d\d[T|\s]\d\d:\d\d/gm;

  return value ?
    regex.test(value) ?
      castDatetime(value) :
      castTimeWithDate(value, date) :
    undefined;
}

export function castTimeWithDate(value: string | undefined, date: string) {
  return value ? castDatetime(`${date}T${value}`) : undefined;
}

export function castNullable<T>(value: T, clone: object, key: string): T | null {
  if(hasOwnDotProperty(clone, key) && value === undefined) {
    // eslint-disable-next-line no-null/no-null
    return null;
  }

  return value;
}

export function castDatetime(value: string) {
  console.log(value);

  if(value) {
    return new Date(value).toISOString().slice(0, 19).replace('T', ' ');
  }

  return undefined;
}

export function castTime(value: string) {
  if(value) {
    const today = new Date();
    const timeSplit = value.split(':');

    today.setHours(parseInt(timeSplit[0]));
    today.setMinutes(parseInt(timeSplit[1]));
    today.setSeconds(0);

    return today.toISOString().slice(11, 19);
  }

  return undefined;
}

export function castDate(date: string) {
  if(date) {
    return new Date(date).toISOString().split('T')[0];
  }

  return undefined;
}

const castingTypes = {
  nullable: castNullable,
  'Y-m-d H:i:s': castDatetimeOrTime,
};

type DropFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;
type First<T> = T extends [infer U, ...unknown[]] ? U : T;

export class CastValue<T extends Castable, E = never> {
  constructor(private value: T) {}

  cast<U extends T['cast'] & keyof typeof castingTypes>(
    castType: First<Exclude<U, E>> & keyof typeof castingTypes,
    ...castArgs: DropFirst<Parameters<typeof castingTypes[First<Exclude<U, E>> & keyof typeof castingTypes]>>
  ) {
    this.value = (castingTypes[castType] as any)(this.value, ...castArgs);

    return (this as CastValue<T, E | First<Exclude<U, E>>>);
  }

  get(): T['type'] {
    return this.value;
  }
}
