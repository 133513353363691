/*
 * Contains the axios instance for the application and exposes functions to
 * send requests through the axios client
 */
import { inCapacitor } from '@/helpers';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Swal from 'sweetalert2';

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_APP_URL || 'http://localhost',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },

  withCredentials: true,
});

export default {
  axiosClient,
  bootstrap() {
    // Perform run time initialisation
    if(inCapacitor) {
      axiosClient.defaults.headers.common['X-Csrf-Mobile'] = true;
    }

    axiosClient.get('/sanctum/csrf-cookie');

    // set up interceptor to redirect to login if the API returns unauthenticated
    axiosClient.interceptors.response.use((response) => response, (error) => {
      const err = error as AxiosError;

      if(
        (err.response.status === 419 ||
          err.response.status === 401) &&
        window.location.pathname !== '/login'
      ) {
        Swal.fire({
          icon: 'error',
          text: 'Your session has expired, please sign back in',
        }).then(() => {
          window.location.pathname = '/login';
        });
      }

      if(err.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Server Error',
          text: 'Please try again or contact support.',
        })
      }

      return Promise.reject(error);
    });
  },

  /*
   * Each request function will send the respective request type with
   * the given data attached. An array containing the data or error will
   * be returned.
   * On success, the first element will contain the response data or a default
   * empty object and have null as the second element.
   * If the request fails, the first element will be null and the second
   * element will contain the error
   */
  async getRequest(url: string, data = {}, config?: AxiosRequestConfig) {
    try {
      const response = await axiosClient.get(url, {
        params: data,
        ...config,
      });

      return [response.data || {}, undefined];
    } catch (err: any) {
      return [undefined, (err as unknown as AxiosError).response];
    }
  },

  async postRequest(url: string, data = {}, config?: AxiosRequestConfig) {
    try {
      const response = await axiosClient.post(url, data, config);

      return [response.data || {}, undefined];
    } catch (err: any) {
      return [undefined, (err as unknown as AxiosError).response];
    }
  },

  async deleteRequest(url: string, data = {}, config?: AxiosRequestConfig) {
    try {
      const response = await axiosClient.delete(url, {
        params: data,
        ...config,
      });

      return [response.data || {}, undefined];
    } catch (err: any) {
      return [undefined, (err as unknown as AxiosError).response];
    }
  },

  async putRequest(url: string, data = {}, config?: AxiosRequestConfig) {
    try {
      const response = await axiosClient.put(url, data, config);

      return [response.data || {}, undefined];
    } catch (err: any) {
      return [undefined, (err as unknown as AxiosError).response];
    }
  },

  async patchRequest(url: string, data = {}, config?: AxiosRequestConfig) {
    try {
      const response = await axiosClient.patch(url, data, config);

      return [response.data, undefined];
    } catch (err: any) {
      return [undefined, (err as unknown as AxiosError).response];
    }
  },
};
